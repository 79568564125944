@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.MuiFormLabel-root.Mui-focused {
  color: red !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #828282 !important;
  border-width: 1px !important;
}

.MuiOutlinedInput-root {
  font-size: 14px !important;
  line-height: 20px !important;
}
.complex-swipeable-list__item-content-right {
    background-color: #B82C54;
    color: white;
    flex: 1 1;
    height: 83%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
}

.complex-swipeable-list__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    width: 64px;
    max-width: 64px;
    text-align: center;
    padding: 8px 4px 4px;
}

.complex-swipeable-list__icon {
    fill: white;
    width: 32px;
    height: 32px;
    margin-top: -4px;
}

