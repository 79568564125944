.complex-swipeable-list__item-content-right {
    background-color: #B82C54;
    color: white;
    flex: 1;
    height: 83%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
}

.complex-swipeable-list__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    width: 64px;
    max-width: 64px;
    text-align: center;
    padding: 8px 4px 4px;
}

.complex-swipeable-list__icon {
    fill: white;
    width: 32px;
    height: 32px;
    margin-top: -4px;
}
